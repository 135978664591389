<template>
  <b-card>
    <b-card-header>
      <b-card-title>{{ journey.name }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        {{ lastUpdateText }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row v-if="journey.hasFunnelData">
        <b-col
          v-for="(item, index) in stageCounts"
          :key="index"
          md="4"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                :variant="item.variant"
                size="48"
              >
                {{ index + 1 }}
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.count }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.name }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>

      <b-row v-else>

        <b-col
          v-for="(item, index) in stageCounts"
          :key="index"
          md="4"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-skeleton type="avatar" />

            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                {{ item.name }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

      </b-row>

      <hr>
      <div class="action">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-primary"
          class="btn-icon"
          @click="routeToFunnelPage()"
        >
          <feather-icon icon="BarChartIcon" />
        </b-button>
        <label>
          <b-badge
            v-if="journey.exec_status != 'SUCCEEDS' "
            pill
            variant="light-warning"
          >
            Refresh pending
          </b-badge>
        </label>

        <div>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
          >
            <feather-icon icon="RefreshCcwIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            @click="routeToEditPage()"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </div>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BCardText,
  BCardHeader, BCardTitle, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BSkeleton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'
// import FunnelGraph from '@/modules/core/components/funnel/FunnelGraph.vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardText,
    // FunnelGraph,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BSkeleton,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    journey: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    //   labels: ['lable1', 'lable2'],
    //   values: [[100], [60]],
      direction: 'vertical',
      // gradientDirection: 'horizontal',
      height: 100,
      width: 300,
      colors: [
        [$themeColors.primary], // color set for "Impressions" segment
        ['#EC77FF'], // color set for "Add To Cart" segment
        ['#7795FF'], // color set for "Buy" segment
      ],
    }
  },
  computed: {
    // values() {
    //   if (!this.journey || !this.journey.counts) {
    //     return []
    //   }
    //   return this.journey.counts.map(i => [i])
    // },
    // labels() {
    //   if (!this.journey || !this.journey.stages) {
    //     return []
    //   }
    //   return this.journey.stages.map((s, i) => s.name ?? `Stage ${i + 1}`)
    // },
    stageCounts() {
      if (!this.journey) {
        return []
      }
      const colors = ['light-primary', 'light-info', 'light-warning', 'light-danger', 'light-success']

      if (!this.journey.payload.counts) {
        return this.journey.payload.stages.map((item, index) => ({
          name: item.name ?? `Stage ${index + 1}`,
          count: 0,
          variant: colors[index] ?? 'light-primary',
        }))
      }

      return this.journey.payload.counts.reduce((all, current, index) => {
        all.push({
          count: current,
          name: this.journey.payload.stages[index].name ?? `Stage ${index + 1}`,
          variant: colors[index] ?? 'light-primary',
        })
        return all
      }, [])
    },
    lastUpdateText() {
      if (!this.journey) {
        return ''
      }

      if (!this.journey.payload.ts_success) {
        return ''
      }

      return `Refreshed ${moment.utc(this.journey.payload.ts_success).local().fromNow()}`
    },

  },
  methods: {
    kFormatter,
    routeToEditPage() {
      this.$router.push({ name: 'journey-edit', params: { id: this.journey.id } })
    },
    routeToFunnelPage() {
      this.$router.push({ name: 'journey-funnel', params: { id: this.journey.id } })
    },
  },
}
</script>

<style scoped>
 .action
 {
   align-items: center;
   justify-content: space-between;
   display: flex;
 }
</style>
