<template>
  <section id="dashboard-analytics">
    <b-row
      v-for="(row, row_index) in displayItems"
      :key="row_index"
      class="match-height"
    >
      <b-col
        v-for="(journey, col_index) in row"
        :key="col_index"
        lg="6"
        md="6"
      >
        <JourneyWidget :journey="journey" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import JourneyWidget from '@/modules/journey/components/JourneyWidget.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('journey')

export default defineComponent({
  components: {
    BRow, BCol, JourneyWidget,
  },
  setup() {

  },
  computed: {
    displayItems() {
      return this.allJourney
        .filter(item => item.status === 'A')
        .reduce((groups, item) => {
          const numRows = groups.length
          let lastRow = 0
          if (numRows > 0) {
            lastRow = numRows - 1
          } else {
            groups.push([])
          }

          if (groups[lastRow].length === 2) {
            groups.push([])
            lastRow += 1
          }
          groups[lastRow].push(item)

          return groups
        }, [])
    },
    ...mapGetters(['allJourney']),
  },
  mounted() {
    this.refresh()
  },
  methods: {

    refresh() {
      this.getAllJourney().finally(() => {

      })
    },
    ...mapActions(['getAllJourney']),
  },
})
</script>
